import "./OrganizationsSubscriptionsTab.css";
import React from "react";
import {MC_Organization} from "../../../common/iot/MC_Organization";
import SubscriptionTile from "../../../common/ui/billing/SubscriptionTile";

export interface OrganizationSubscriptionsTabProps {
    org: MC_Organization;
}
function OrganizationSubscriptionsTab({org}: OrganizationSubscriptionsTabProps) {
    const subCount: number = org.simpleSubscriptions.length;
    // UI
    return (<div>
        <p className={"note"}>{org.name} has {subCount} {(subCount !== 1) ? "subscriptions" : "subscription"}.</p>
        {/* Subs */}
        {org.simpleSubscriptions.map((x) => <SubscriptionTile key={x.id} sub={x}/>)}
    </div>);
}

export default OrganizationSubscriptionsTab;