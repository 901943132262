import {MC_Device} from "../common/iot/MC_Device";

// Class to define what devices can be created
export interface CustomDevice {
    rawType: string,
    fullTypeName: string,
    description: string
}
// Create device
export class CustomDeviceFactory {

    // -JAS Devices-
    public static DEVICE_TYPE_TH: string = "TH";
    public static DEVICE_TYPE_CO2: string = "CO2";
    public static DEVICE_TYPE_CHILL: string = "CHILL";
    public static DEVICE_TYPE_CC: string = "CC";
    public static DEVICE_TYPE_FM: string = "FM";
    public static FULL_TYPE_TH: string = "Temperature/Humidity Sensor";
    public static FULL_TYPE_CO2: string = "CO2 Sensor";
    public static FULL_TYPE_CHILL: string = "Chiller Monitor";
    public static FULL_TYPE_CC: string = "Carbonation Controller";
    public static FULL_TYPE_FM: string = "Fermentation Monitor";

    // -Monnit Devices-
    public static DEVICE_TYPE_MONNIT_TEMP: string = "MONNIT_TEMP";
    public static DEVICE_TYPE_MONNIT_CO2: string = "MONNIT_CO2";
    public static DEVICE_TYPE_MONNIT_WATER: string = "MONNIT_WATER";
    public static FULL_TYPE_MONNIT_TEMP: string = "Monnit Temperature Sensor";
    public static FULL_TYPE_MONNIT_CO2: string = "Monnit CO2 Sensor";
    public static FULL_TYPE_MONNIT_WATER: string = "Monnit Water Sensor";

    public static SUPPORTED_DEVICES: CustomDevice[] = [
        {
            rawType: this.DEVICE_TYPE_TH,
            fullTypeName: this.FULL_TYPE_TH,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_CO2,
            fullTypeName: this.FULL_TYPE_CO2,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_CHILL,
            fullTypeName: this.FULL_TYPE_CHILL,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_CC,
            fullTypeName: this.FULL_TYPE_CC,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_FM,
            fullTypeName: this.FULL_TYPE_FM,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_MONNIT_TEMP,
            fullTypeName: this.FULL_TYPE_MONNIT_TEMP,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_MONNIT_CO2,
            fullTypeName: this.FULL_TYPE_MONNIT_CO2,
            description: ""
        },
        {
            rawType: this.DEVICE_TYPE_MONNIT_WATER,
            fullTypeName: this.FULL_TYPE_MONNIT_WATER,
            description: ""
        }
    ];

    public static fromData(data: any): MC_Device {
        const type: string | null = (data[MC_Device.FIELD_TYPE] != null)
            ? data[MC_Device.FIELD_TYPE] as string
            : null
        ;
        if (type == null) {
            throw "Missing device type";
        }
        // Custom device types
        switch (type) {}
        // Return base device as default
        return new MC_Device(data);
    }

    public static getArrayFromData(dataArray: any[]): MC_Device[] {
        let devices: MC_Device[] = [];
        for (let i = 0; i < dataArray.length; i++) {
            try {
                devices = devices.concat(this.fromData(dataArray[i]));
            } catch (e) {
                console.error("Device parse err: " + e + ": " + JSON.stringify(dataArray[i]));
            }
        }
        return devices;
    }

    public static getFullDeviceType(type: string): string {
        switch (type) {
            case this.DEVICE_TYPE_TH: return this.FULL_TYPE_TH;
            case this.DEVICE_TYPE_CO2: return this.FULL_TYPE_CO2;
            case this.DEVICE_TYPE_CHILL: return this.FULL_TYPE_CHILL;
            case this.DEVICE_TYPE_CC: return this.FULL_TYPE_CC;
            case this.DEVICE_TYPE_FM: return this.FULL_TYPE_FM;
            case this.DEVICE_TYPE_MONNIT_TEMP: return this.FULL_TYPE_MONNIT_TEMP;
            case this.DEVICE_TYPE_MONNIT_CO2: return this.FULL_TYPE_MONNIT_CO2;
            case this.DEVICE_TYPE_MONNIT_WATER: return this.FULL_TYPE_MONNIT_WATER;
        }
        return "Unknown";
    }

}
