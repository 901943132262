
export class CustomConstants {

    // Version
    public static ADMIN_SOFTWARE_VERSION: string = "v0.0.2";

    // Admin
    public static ADMIN_SOFTWARE_NAME: string = "JAS Brew";
    public static ADMIN_SOFTWARE_ENDPOINT: string = "https://admin.app.jasbrew.com";
    public static MONITORING_SOFTWARE_URL: string = "https://app.jasbrew.com";

    // Backend
    public static API_ENDPOINT: string = "https://us-central1-jas-brew.cloudfunctions.net/v2";
    public static SERVER_UIDS: string[] = [
        "jas-data-server",
        "jas-schedule-server",
        "jas-billing-server",
        "jot-server"
    ];

    // Return the stripe fee amount for a given price ID (100 = $1)
    public static getJASFeeForPriceID(priceID: string | null): number {
        return 0;
    }

}
