import React, {CSSProperties, useEffect} from "react";
import './LogIn.css';
import {CustomConstants} from "../custom/CustomConstants";
import whiteLogo from "../white_logo.png";
import {useNavigate} from "react-router-dom";
import {Box, Button, Card, CardActions, CardContent, CircularProgress, TextField} from "@mui/material";
import {MC_Backend} from "../common/MC_Backend";
import {MC_Constants} from "../common/MC_Constants";
import CompleteMFADialog, {CompleteMFADialogConfig} from "../common/ui/dialog/CompleteMFADialog/CompleteMFADialog";

const linkStyle: CSSProperties = {
    color: "white",
    textDecorationColor: "white"
};

interface LoginState {
    curEmail: string;
    curPass: string;
    loading: boolean;
    errMsg: string | null;
}
const LogIn = (props: any) => {
    // Constants
    const navigate = useNavigate();
    //const title: string = CustomConstants.ADMIN_SOFTWARE_NAME;
    const monitoringLink: string = CustomConstants.MONITORING_SOFTWARE_URL;
    const jasLink: string = MC_Constants.JAS_LINK;
    const version: string = CustomConstants.ADMIN_SOFTWARE_VERSION;

    // State
    const [mfaDialogOpen, setMFADialogOpen] = React.useState<boolean>(false);
    const [mfaDialogConfig, setMFADialogConfig] = React.useState<CompleteMFADialogConfig | null>(null);
    const [inputState, setInputState] = React.useState<LoginState>({
        curEmail: "", curPass: "", loading: false, errMsg: null
    });
    const onLoginBtnClick = () => {
        if (!inputState.loading) {
            setInputState({...inputState, loading: true});
            MC_Backend.getInstance().signIn(
                inputState.curEmail,
                inputState.curPass,
                setMFADialogOpen,
                setMFADialogConfig
            ).then(() => {
                navigate("/core");
            }).catch((err) => {
                console.error("Log in error: " + JSON.stringify(err));
                setInputState({...inputState, loading: false, errMsg: err});
            });
        }
    };

    // Reroute to core if already logged in
    useEffect(() => {
        if (MC_Backend.getInstance().isSignedIn()) {
            console.log("User already signed in, rerouting...");
            navigate("/core");
        }
    }, [navigate]);

    // UI
    return (
        <div className="login-root">

            {/* MFA Dialog */}
            <CompleteMFADialog open={mfaDialogOpen} setOpenFn={setMFADialogOpen} config={mfaDialogConfig}/>

            {/* Header */}
            <header className="login-header">
                <img src={whiteLogo} className="main-logo" alt="logo" />
                <div>
                    <span className={"title"}>JAS Brew Admin Portal</span>
                </div>
                <br/>
            </header>

            <div className={"card-div"}>
                <Box sx={{ minWidth: 275, maxWidth: 500 }}>
                    <Card variant="outlined">
                        <CardContent className={"card-content"}>

                            {/* Login input */}
                            <div className={"login-input-div"}>

                                <span className={"login-input-title"}>Log In</span>

                                <br/>

                                {/* Email Input */}
                                <TextField label="Email" variant="outlined" type={"text"}
                                           className={"text-input"} value={inputState.curEmail}
                                           onChange={(event) => {
                                               setInputState({...inputState, curEmail: event.target.value});
                                           }}
                                />

                                <br/>

                                {/* Password Input */}
                                <TextField label="Password" variant="outlined" type={"password"}
                                           className={"text-input"} value={inputState.curPass}
                                           onChange={(event) => {
                                               setInputState({...inputState, curPass: event.target.value});
                                           }}
                                />

                            </div>

                        </CardContent>
                        <CardActions className={"card-actions"}>

                            {/* Loading indicator */}
                            {

                                (inputState.loading) &&
                                <div>
                                    <CircularProgress className={"login-spinner"}/>
                                </div>
                            }

                            {/* Log In button */}
                            {
                                (!inputState.loading) &&
                                <React.Fragment>
                                    <div>
                                        <Button variant="contained" className={"login-btn"} onClick={onLoginBtnClick}>
                                            <span className={"login-btn-text"}>Log In</span>
                                        </Button>
                                        <br/>
                                        <span className={"version"}>{version}</span>
                                    </div>
                                </React.Fragment>
                            }
                        </CardActions>

                        {/* Error message */}
                        {
                            (inputState.errMsg != null) &&
                            <p className={"error-note"}>{inputState.errMsg}</p>
                        }

                    </Card>
                </Box>
            </div>

            <br/>

            {/* Link to main software */}
            <span>
                This is the management portal for the primary&nbsp;
                <a target="_blank" rel="noopener noreferrer" href={monitoringLink} style={linkStyle}>
                    Monitoring Software
                </a>
            </span>

            <br/><br/>

            {/* Powered by JAS */}
            <span className={"power"}>
              Powered by&nbsp;
              <a target="_blank" rel="noopener noreferrer" href={jasLink} style={linkStyle}>
                  <b>JAS Monitoring</b>
              </a>
            </span>

        </div>
    );
}

export default LogIn;