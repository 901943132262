import "./ViewPaper.css";
import OrganizationItemList from "../item-list/list/OrganizationItemList";
import {Button} from "@mui/material";
import React from "react";
import {MC_Organization} from "../../iot/MC_Organization";

export interface ViewOrganizationsProps {
    organizationIDs: string[];
    desc: string;
    addAction?: () => void;
    removeAction?: (o: MC_Organization) => void;
}

function ViewOrganizations(props: ViewOrganizationsProps) {
    return (
        <div>

            {/* Title and description */}
            <p className={"note"}>
                {props.desc}
            </p>

            {/* Orgs item list */}
            <OrganizationItemList organizationIDs={props.organizationIDs} removeAction={props.removeAction}/>

            <br/>
            {/* [Optional] Add Action */}
            {
                (props.addAction != null)
                &&
                <Button variant="contained" color={"success"}
                        onClick={() => props.addAction!()}>
                    Add Organization
                </Button>
            }

        </div>
    );
}

export default ViewOrganizations;