import "./SubscriptionTile.css";
import {MC_Device} from "../../iot/MC_Device";
import {SimpleInvoice, SimpleSubscription} from "../../iot/simple_stripe_types";
import {Divider, Paper} from "@mui/material";
import {DevicesContext, OrganizationsContext} from "../../../App";
import {MC_Organization} from "../../iot/MC_Organization";
import OrganizationItem from "../item-list/OrganizationItem";
import MCLoadingBar from "../misc/MCLoadingBar";
import React from "react";
import {MC_Constants} from "../../MC_Constants";
import {CurrencyExchange} from "@mui/icons-material";
import DeviceItem from "../item-list/DeviceItem";
import {MC_Backend} from "../../MC_Backend";

interface SubscriptionTileInterface {
    sub: SimpleSubscription;
}
function SubscriptionTile({sub}: SubscriptionTileInterface) {
    // Load orgs if needed
    let mcOrgsCtx = React.useContext(OrganizationsContext);
    React.useEffect(() => {
        if (mcOrgsCtx.organizations == null && !mcOrgsCtx.loading && mcOrgsCtx.errMsg == null) {
            MC_Backend.getInstance().loadOrganizations().finally();
        }
    }, [mcOrgsCtx]);
    // Load devices if needed
    let mcDevicesCtx = React.useContext(DevicesContext);
    React.useEffect(() => {
       if (mcDevicesCtx.devices == null && !mcDevicesCtx.loading && mcDevicesCtx.errMsg == null) {
           MC_Backend.getInstance().loadDevices().finally();
       }
    }, [mcDevicesCtx]);
    // Parse data
    const createdStr: string = MC_Constants.getReadableDateStr(new Date(sub.created * 1000));
    //const periodEndStr: string = MC_Constants.getReadableDateStr(new Date(sub.currentPeriodEnd * 1000));
    const orgID: string | null = MC_Constants.parseOrgIDFromSubscription(sub);
    const deviceIDs: string[] = MC_Constants.parseDeviceIDsFromSubscription(sub);
    const latestInvoice: SimpleInvoice | null = sub.latestInvoice;
    let invoicePaidStr: string;
    let invoiceDateStr: string;
    let invoicePDFLink: string | null;
    if (latestInvoice != null) {
        invoicePaidStr = "Paid " + MC_Constants.getUSDStr(latestInvoice.amountPaid);
        const createdDate: Date = new Date(latestInvoice.created * 1000);
        invoiceDateStr = MC_Constants.getReadableDateStr(createdDate);
        invoicePDFLink = latestInvoice.invoicePDFURL;
    } else {
        invoicePaidStr = "Unpaid";
        invoiceDateStr = MC_Constants.getReadableDateStr(null);
        invoicePDFLink = null;
    }
    // UI
    return (
        <Paper elevation={3} className={"content-start"}>
            <div className={"sub-tile-root"}>

                {/* Header row */}
                <div className={"sub-header-row"}>

                    {/* Icon col */}
                    <div className={"sub-header-icon-col"}>
                        <CurrencyExchange sx={{color: (sub.cancelAtPeriodEnd) ? "orange" : "green"}} fontSize={"large"} />
                    </div>

                    {/* Name col */}
                    <div className={"sub-header-name-col"}>
                        <span className={"sub-title"}>Annual Subscription</span>
                        <span className={"sub-subtitle"}>Created on {createdStr}</span>
                    </div>

                </div>

                <Divider className={"divider"}/>

                {/* Owner row */}
                <div className={"sub-data-row"}>
                    <span className={"note"}>Owned by: </span>
                    <OrganizationsContext.Consumer>
                        {(orgsCtx) => {
                            let foundOrg: MC_Organization | null = null;
                            if (orgsCtx.organizations != null) {
                                for (let org of orgsCtx.organizations) {
                                    if (orgID != null && org.id === orgID) {
                                        foundOrg = org;
                                    }
                                }
                            }
                            return (<div className={"sub-org-div"}>
                                {/* Loading */}
                                <MCLoadingBar
                                    loadingMessage={"Loading Organization..."}
                                    loading={orgsCtx.loading}
                                    errorMessage={orgsCtx.errMsg}
                                />
                                {/* Org */}
                                {(foundOrg != null) &&
                                    <OrganizationItem nameLink={true} org={foundOrg}/>
                                }
                                {/* Missing Org */}
                                {(foundOrg == null && orgsCtx.organizations != null) &&
                                    <span className={"error-note"}>Could not find org: {orgID}</span>
                                }
                            </div>);
                        }}
                    </OrganizationsContext.Consumer>
                    <Divider/>
                </div>

                <Divider className={"divider"}/>

                {/* Invoice row */}
                <div className={"sub-data-row"}>
                    <span className={"note"}>Last Invoice: {invoicePaidStr} on {invoiceDateStr}</span>
                    {(invoicePDFLink != null) &&
                        <span className={"invoice-pdf-link"}>
                            <a href={invoicePDFLink}>Download Latest Invoice</a>
                        </span>
                    }
                    <Divider/>
                </div>

                <Divider className={"divider"}/>

                {/* Devices row */}
                <div className={"sub-data-row"}>
                    <span className={"note"}>Devices Included: </span>
                    <DevicesContext.Consumer>
                        {(devicesCtx) => {
                            let foundDevices: MC_Device[] = [];
                            let missingIDs: string[] = [];
                            if (devicesCtx.devices != null) {
                                // Parse devices
                                foundDevices = devicesCtx.devices.filter((d) => deviceIDs.includes(d.id));
                                const foundIDs: string[] = foundDevices.map((d) => d.id);
                                // Determine if any are missing
                                missingIDs = deviceIDs.filter((id) => !foundIDs.includes(id));
                            }
                            return (<div className={"sub-devices-div"}>
                                {/* Loading devices */}
                                <MCLoadingBar
                                    loading={devicesCtx.loading}
                                    loadingMessage={"Loading Devices..."}
                                    errorMessage={devicesCtx.errMsg}
                                />
                                {/* Devices */}
                                {foundDevices.map((d) => <DeviceItem key={d.id} nameLink={true} device={d}/>)}
                                {/* Missing devices */}
                                {(missingIDs.length > 0) &&
                                    <div>
                                        <p className={"error-note"}>The following devices could not be found: </p>
                                        {missingIDs.map((id) => <p className={"note"}>{id}</p>)}
                                    </div>
                                }
                                {/* No devices */}
                                {(deviceIDs.length === 0) &&
                                    <p className={"error-note"}>No devices associated with this subscription.</p>
                                }
                            </div>);
                        }}
                    </DevicesContext.Consumer>
                </div>
            </div>
        </Paper>
    );
}

export default SubscriptionTile;